import { generateUUID, getCookie, setCookie, getUrlParam } from "@ewt/eutils";

import { Base64 } from "js-base64";

import { IhashQueue } from "./@types/index.d";

const cookieId = "big_data_cookie_id";
const cookieTime = "big_data_cookie_time";

const { userAgent: ua, connection = { effectiveType: "" } } = navigator;

export function registerEvent(
  type: string,
  listener,
  useCapture: boolean
): void {
  if (document.addEventListener) {
    window.addEventListener(type, listener, useCapture);
  } else if (document.attachEvent) {
    window.attachEvent(`on${type}`, listener);
  }
}

export function removeEvent(type, listener): void {
  if (document.addEventListener) {
    window.removeEventListener(type, listener);
  } else if (document.attachEvent) {
    window.detachEvent(`on${type}` + type, listener);
  }
}

export function getNetWork(): string {
  const { effectiveType: newtwork } = connection;
  return newtwork;
}

export function getUserAgent(): string {
  return ua;
}

export function getUserClient(): string {
  if (/windows phone/i.test(ua)) return "windows phone";
  if (/android/i.test(ua)) return "android";
  if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) return "ios";
  return "web";
}

export function getPlatform(): string {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua))
    return "h5";
  return "web";
}

export function getRefer(hashArr: Array<IhashQueue> = []): string {
  // hash路由从hashArr中获取
  if (hashArr.length > 0) return encodeURIComponent(hashArr[0].refer);
  // 优先从document中获取refer信息
  if (document.referrer) return encodeURIComponent(document.referrer);
  // webview 中documment 中 refer 有可能丢失, 与客户端约定url携带
  if (!document.referrer)
    return encodeURIComponent(Base64.decode(getUrlParam("referUrl")));
  return "";
}

export function getInId(hashArr: Array<IhashQueue> = []): string {
  if (hashArr.length > 0) return hashArr[0].id;
  return "";
}

export function getCookieId(): string {
  // 获取游客cookieId
  let id = getCookie(cookieId);
  if (!id) {
    id = generateUUID();
    const time = new Date().getTime();
    setCookie(cookieId, id, { expires: 365 });
    setCookie(cookieTime, time, { expires: 365 });
  }
  return id;
}

export function getCookieTime(): string {
  return getCookie(cookieTime) || "";
}

export function isInMstApp(): boolean {
  const {
    navigator: { userAgent },
  } = window;
  const ewtFlagIndex = userAgent.indexOf("EWT/");
  const isInMstApp = ewtFlagIndex !== -1 ? 2 > 1 : false;
  return isInMstApp;
}

export function getUid(): string {
  let uid = "";
  // uid 读取cookie标识位，准确性按数组顺序执行
  const query = ["token", "ewt_user", "user"];
  const cookie = getCookie();
  const isInApp = isInMstApp();

  // 优先从cookie读取token信息
  for (const value of query) {
    if (cookie[value]) {
      uid = cookie[value];
      break;
    }
  }

  // webview 补偿从url读取token .
  // 2022.07.12 由于cookie残留，导致上报的uid不准，所以在app中uid优先从url中获取
  if (!uid || isInApp) {
    uid = getUrlParam("token", location.search);
  }

  // userId取值
  if (uid.match("tk=")) {
    uid = getUrlParam("tk", uid.split("-")[0]);
  } else {
    uid = uid.split("-")[0];
  }
  return uid;
}

export function getUrl(hashArr: Array<IhashQueue> = []): string {
  // hash路由从hashArr中获取
  if (hashArr.length > 0) return hashArr[0].url;
  return encodeURIComponent(location.href);
}

export function hasOldUrl(source: Array<IhashQueue>, target: string): boolean {
  let res = false;
  for (const value of source) {
    if (value.url === target) {
      res = true;
      break;
    }
  }
  return res;
}

export function getBrowserType() {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Firefox") > -1) {
    return "Firefox";
  } else if (userAgent.indexOf("Edge") > -1) {
    return "Edge";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    return "Opera";
  } else if (userAgent.indexOf("Chrome") > -1) {
    return "Chrome";
  } else if (userAgent.indexOf("Safari") > -1) {
    return "Safari";
  } else if (
    userAgent.indexOf("MSIE") > -1 ||
    userAgent.indexOf("Trident") > -1
  ) {
    return "IE";
  }
  return "Unknown";
}
