import {
  generateUUID,
} from '@ewt/eutils';

import {
  version
} from '../package.json';

import {
  IhashQueue,
  IhashParam,
  ILog,
} from './@types/index.d';

import {
  getNetWork,
  getUserAgent,
  getUserClient,
  getPlatform,
  getRefer,
  getCookieId,
  getCookieTime,
  getUid,
  getUrl,
  getInId,
  registerEvent,
  removeEvent,
  hasOldUrl,
  getBrowserType,
} from './utils';

import md5 from 'js-md5'

class Analytics {
  private static sn = 'meisah_ewt_web';
  private static id = '';
  private static jsversion = version;
  private static hashQueue: Array<IhashQueue> = [];
  private static inTime = new Date().getTime();
  private static focusInTime = new Date().getTime();
  private static browserType = getBrowserType();
  private static uid = getUid;
  private static cookieid = getCookieId;
  private static cookietime = getCookieTime;
  private static platform = getPlatform;
  private static userclient = getUserClient;
  private static useragent = getUserAgent;
  private static newtwork = getNetWork;
  private static refer = getRefer;
  private static url = getUrl;
  private static duration = 0;
  private static focustime = 0;
  public static extra: object | string = '';
  public static oldUrl = location.href;
  // 当前路由是否为browserHistory模式
  public static browserHistoryMode = false;

  public constructor() {
    // 页面加载事件
    this._load();
    // 支持browserHistory模式
    this._addBrowserHistoryEvent();
    registerEvent('hashchange', this._hashchange, false);
    registerEvent('popstate', this._popstate, false);
    registerEvent('visibilitychange', this._visibility, false)
    registerEvent('beforeunload', this._unload, false)
    registerEvent('click', this._DomEventDelegationClick, false);
  }

  private _load() {
    // page in
    // console.log('_load');
    const inBase = Analytics.getBaseData();
    const id = generateUUID();
    const inParam: ILog = {
      ...inBase,
      id,
      eventType: 'page',
      inout: 'in',
      focustime: '',
      browserEvent: `${Analytics.browserType}-load`,
    }
    Analytics.upload(inParam)
    // 生成外链refer referId
    const path = {
      url: Analytics.url(),
      id,
      refer: Analytics.refer(Analytics.hashQueue),
      referId: generateUUID()
    }
    Analytics.hashQueue.push(path);
  }

  private _addBrowserHistoryEvent() {
    const beforeUpdateState = (state) => {
      // 如果该hook被触发，则认为browserHistory模式为true
      Analytics.browserHistoryMode = true;
      // 记录变化前的location
      // console.log('beforeUpdateState', state, window.location.href);
      Analytics.oldUrl = window.location.href;
    }
    const afterUpdateState = (state) => {
      // 手动触发popstate
      // console.log('afterUpdateState', state, window.location.href);
      this._popstate();
    }
    const history = window.history;
    const pushState = history.pushState;
    const replaceState = history.replaceState;
    // 重写pushState方法，加入监听器
    history.pushState = function (state) {
      beforeUpdateState({state});
      const result = pushState.apply(history, arguments);
      afterUpdateState({state})
      return result;
    };
    // 重写replaceState方法，加入监听器
    history.replaceState = function (state) {
      beforeUpdateState({state});
      const result = replaceState.apply(history, arguments);
      afterUpdateState({state})
      return result;
    };
  }

  private _popstate = () => {
    // 只在browserHistoryMode下触发
    if (!Analytics.browserHistoryMode) {
      return;
    }
    this._onUrlChange({
      newURL: window.location.href,
      oldURL: Analytics.oldUrl,
    }, 'popstate')
  }
  private _hashchange = (params: IhashParam) => {
    // 只在非browserHistoryMode下触发
    if (Analytics.browserHistoryMode) {
      return;
    }
    this._onUrlChange(params, 'hashchange');
  }

  // 页面URL变化
  private _onUrlChange(params: IhashParam, event: string): void {
    let {newURL, oldURL} = params;
    oldURL = oldURL || Analytics.oldUrl;
    newURL = newURL || location.href;
    console.log('_onUrlChange', event, oldURL, newURL);
    // 上一个页面的out事件触发
    if (oldURL) {
      if (hasOldUrl(Analytics.hashQueue, oldURL)) {
        const outBase = Analytics.getBaseData();
        const outParam: ILog = {
          ...outBase,
          eventType: 'page',
          inout: 'out',
          inId: getInId(Analytics.hashQueue),
          duration: Analytics.getDuration(),
          focustime: Analytics.setFousTime(),
          browserEvent: `${Analytics.browserType}-${event}`,
        }
        Analytics.upload(outParam);
      }
    }
    // 添加当前路由信息
    const path = {
      url: newURL,
      id: generateUUID(),
      refer: oldURL,
      referId: Analytics.hashQueue[0].referId
    }

    // 队列弹出  推入
    Analytics.hashQueue.shift();
    Analytics.hashQueue.push(path);

    // 页面in事件
    // 获取基础参数
    const inBase = Analytics.getBaseData();
    const inParam: ILog = {
      ...inBase,
      eventType: 'page',
      clickId: '',
      inout: 'in',
      focustime: '',
    }
    Analytics.upload(inParam);

    // 设置页面inTime focusInTime
    Analytics.inTime = new Date().getTime();
    Analytics.focusInTime = new Date().getTime();
    Analytics.focustime = 0;

    // 记录当前URL为oldUrl
    Analytics.oldUrl = newURL;
  }

  // 页面可见性
  private _visibility(event) {
    const visible = document.visibilityState;
    if (visible === 'visible') {
      Analytics.focusInTime = new Date().getTime();
    } else if (visible === 'hidden') {
      Analytics.setFousTime()
    }
  }

  // 页面关闭，刷新
  private _unload(e) {
    const outBase = Analytics.getBaseData();
    const outParam: ILog = {
      ...outBase,
      eventType: 'page',
      clickId: '',
      inout: 'out',
      inId: getInId(Analytics.hashQueue),
      extra: '',
      duration: Analytics.getDuration(),
      focustime: Analytics.setFousTime(),
      browserEvent: `${Analytics.browserType}-unload`,
    }
    const isIE = Analytics.browserType === 'IE';

    // 如果是IE浏览器，发送GET请求，否则发送POST请求
    Analytics.upload(outParam, !isIE);
    // (e || window.event).returnValue = ''; // 注释掉，不阻止用户离开
  }

  private static getBaseData() {
    const jsVersion = Analytics.jsversion;
    const id = Analytics.id;
    const sn = Analytics.sn;
    const uid = Analytics.uid();
    const cookieId = Analytics.cookieid();
    const cookieTime = Analytics.cookietime();
    const platform = Analytics.platform();
    const userClient = Analytics.userclient();
    const url = Analytics.url(Analytics.hashQueue);
    const userAgent = Analytics.useragent();
    const network = Analytics.newtwork();
    const refer = Analytics.refer(Analytics.hashQueue);
    const extra = Analytics.extra;
    const focustime = Analytics.focustime;
    const duration = Analytics.duration;
    const clickId = '';
    const inId = '';
    const inout = '';

    return {
      id,
      uid,
      clickId,
      inId,
      inout,
      cookieId,
      cookieTime,
      platform,
      userClient,
      url,
      userAgent,
      jsVersion,
      network,
      refer,
      extra,
      focustime,
      duration,
      sn
    }
  }

  // 停留页面时长
  private static getDuration() {
    const inTime = Analytics.inTime
    const now = new Date().getTime();
    return now - inTime
  }

  // 设置焦点时间
  private static setFousTime() {
    const inTime = Analytics.focusInTime;
    const now = new Date().getTime();
    Analytics.focustime += (now - inTime);
    // 重置focusInTime，防止该方法被连续调用时导致的数据不准确（翻倍）问题
    Analytics.focusInTime = now;
    return Analytics.focustime
  }

  private static setExtra = (params: object): object => params;

  public page = (params: object): void => {
    Analytics.extra = Analytics.setExtra(params)
  }

  // 销毁
  public destroy = () => {
    try {
      removeEvent('hashchange', this._hashchange);
      removeEvent('popstate', this._popstate);
      removeEvent('visibilitychange', this._visibility)
      removeEvent('beforeunload', this._unload)

    } catch (error) {

    }
  }

  public app = (inout: string, duration: string) => {
    const inBase = Analytics.getBaseData();
    const id = generateUUID();
    const param: ILog = {
      ...inBase,
      id,
      eventType: 'page',
      inout,
      focustime: inout === 'in' ? '' : Analytics.focustime,
      duration: inout === 'in' ? '' : Analytics.duration,
    }
    Analytics.upload(param)

    // 设置页面inTime focusInTime
    Analytics.inTime = new Date().getTime();
    Analytics.focusInTime = new Date().getTime();
    Analytics.focustime = 0;
  }

  public click = ({id = '', extra = '', clickId = '', ...other}) => {
    const clickParams = Analytics.getBaseData();
    const params: ILog = {
      ...clickParams,
      eventType: 'click',
      clickId: id || clickId,
      extra: extra || {
        ...other
      },
    }
    Analytics.upload(params)
  }
  // private _sleep = (delay: number) => {
  //   const start = (new Date()).valueOf();
  //   while ((new Date()).valueOf() - start < delay) {
  //   }
  // }
  /**
   * dom 层次的点击事件
   * 原因：为兼容老版本内此监听方法
   */
  private _DomEventDelegationClick = (e) => {
    const id = e.target.getAttribute('data-stat-id'); // 只判断当前点击的元素是否存在固有属性
    if (id) { // 如果存在相应属性的值，则进行上报，上报单独clickId值
      this.click({
        id,
      });
    }
  }

  /**
   * 上报区分省份序号
   * 原因：支持300万用户同时在线
   */
  private static _generateHostByHostName = (hostname) => {
    const currentHost = `${window.location.host}`;
    const mainDomain = currentHost.replace('.ewt360.com', '');
    const num = mainDomain.replace(/[^0-9]/ig, '');
    const str = `${hostname}${num}`;
    return str;
  }

  /**
   * ajax
   */
  private static ajaxRequest = (url: string, params: string, isPost = true) => {
    const signStr = `log=${params}&key=eo^nye1j#!wt2%v)`;
    const sign = md5(signStr);
    const ts = new Date().getTime();

    // 实例化请求对象
    const ajax = new XMLHttpRequest();
    // 设置请求方式、请求地址、是否异步（固定异步）
    if (isPost) {
      ajax.open('POST', url, true);
      ajax.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      // 监控请求结果
      ajax.onreadystatechange = () => {
        if (ajax.readyState === 4 && ajax.status === 200) {
          // 成功后处理
          try {
            const res = ajax.responseText;
            if (!res) {
              console.log('大数据日志上报成功，返回信息为空');
            } else {
              const resJson = JSON.parse(ajax.responseText);
              console.log('大数据日志上报服务成功返回，返回信息为：', resJson);
            }
          } catch (e) {
            console.log('大数据日志上报服务返回解析出错，错误信息为：', e);
          }
        }
      }
      // 发送请求
      ajax.send(`sn=meisah_ewt_web&log=${window.encodeURIComponent(params)}&ts=${ts}&sign=${sign}`);
    } else {
      ajax.open('GET', `${url}?sn=meisah_ewt_web&log=${window.encodeURIComponent(params)}&ts=${ts}&sign=${sign}`, true);
      // 监控请求结果
      ajax.onreadystatechange = () => {
        if (ajax.readyState === 4 && ajax.status === 200) {
          // 成功后处理
          try {
            const res = ajax.responseText;
            if (!res) {
              console.log('大数据日志上报成功，返回信息为空');
            } else {
              const resJson = JSON.parse(ajax.responseText);
              console.log('大数据日志上报服务成功返回，返回信息为：', resJson);
            }
          } catch (e) {
            console.log('大数据日志上报服务返回解析出错，错误信息为：', e);
          }
        }
      }
      ajax.send(null);
    }
    // ajax.send(null);
  }

  private static upload(params: ILog, isPost = true) {
    params.jsVersion = '0.0.3'
    const bigDataParams = JSON.stringify(params);
    // host
    const clgHost = (window.location.href.indexOf('ewt360') > -1) ? Analytics._generateHostByHostName('clog') : Analytics._generateHostByHostName('clogoffline');
    // ajax上报信息
    const uploadHost = `${window.location.protocol}//${clgHost}.ewt360.com/`;
    Analytics.ajaxRequest(uploadHost, bigDataParams, isPost);
  }

  // 兼容废弃方法
  public sleep() {
  }

  public awake() {
  }
}

const MSTAnalytics = new Analytics();

export default MSTAnalytics;
